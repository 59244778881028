/*
 * https://docs.amplify.aws/lib/project-setup/create-application/q/platform/js
 *   - "Angular 6+ does not include shims for 'global' or 'process'."
 */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
(window as any).global = window;
/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
(window as any).process = {
  env: { DEBUG: undefined }
};
